import React, { useCallback } from "react";
import {
  ELEMENT_PARAGRAPH,
  findNode,
  getEndPoint,
  insertNodes,
  isElement,
  PlateEditor,
  removeNodes,
  setSelection,
  splitNodes,
  ToolbarButton,
  ToolbarButtonProps,
  usePlateEditorState,
  withoutNormalizing,
} from "@udecode/plate";
import { CURRENT_COLOR, TooltipIcon } from "../../../partials";
import {
  FEATURE_SPLIT_CHAPTER,
  isRangeSelected,
  getEditorNodesCount,
  getMidPointOfSplit
} from "../";
import { MyEditor, MySceneElement } from "../../../config/typescript";
import useRootStore from "../../../../../store/useRootStore";
import { allowFeatureForTextMessages } from "../../text-message/config";
import { allowFeatureForCalloutBoxes } from "../../callout-box/config";
import { SplitChapterIcon } from "../../../../../content/icons";
import { findAllNodesInDocByType } from "../../../../../utils/slate";
import { BaseEditor, BaseElement, Editor, Location } from "slate";
import { ELEMENT_ORNAMENTAL_BREAK, ELEMENT_SCENE } from "../../ornamental-break";
import { NodeType } from "../../types";
import { initBody } from "../../../../../utils/initials";
import { message } from "antd";
import { SceneUtils } from "../../../../../utils/scene/sceneServices";

export const SplitChapterToolbarButton = (_: Omit<ToolbarButtonProps, "icon">) => {
  const editor = usePlateEditorState() as MyEditor;
  const { currentScene } = useRootStore().chapterStore;
  const enabled = allowFeatureForTextMessages(editor as PlateEditor) && allowFeatureForCalloutBoxes(editor as PlateEditor) && !currentScene;
  const { book, getCurrentStoredBook, getChapterById, saveChapterBody: saveChapterBodyToLocal, addNewChapter, getChapterMatterById } = useRootStore().bookStore;
  const { chapterMeta } = useRootStore().chapterStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const refreshPDFCache = useCallback(async () => {
    const { frontMatterIds, chapterIds } =
      getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds];
    const chapterData = await getChapterById(allChapterIds);
    const chapterCacheData = chapterData.map(
      ({ _id, type, startOn }) =>
      ({
        chapterId: _id,
        chapterType: type,
        startOn,
      } as IPDFCacheStore.ChapterCacheMetaData)
    );
    refreshCache(book._id, "chapter-add", {
      "chapter-add": { chapters: chapterCacheData },
    });
  }, [book, getCurrentStoredBook, refreshCache]);

  return (
    // <div className={sceneIndex !== null ? "toolbar-button-disable" : ""}>
    <div>
      <TooltipIcon title={"Split chapter"}>
        <div className={`${enabled ? "" : "plate-disabled"}`}>
          <ToolbarButton
            type={FEATURE_SPLIT_CHAPTER}
            icon={<SplitChapterIcon color={CURRENT_COLOR} />}
            onMouseDown={async () => {
              // Warn if a range is selected
              if(isRangeSelected(editor)){
                message.warn("Selection cannot be a range but a point in editor to make the split.");
                return;
              }
              const at = editor.selection;

              //If selection cannot be identified then alert and return;
              if (!at || getEditorNodesCount(editor) === 0) {
                message.warn("Select a point in the editor to make the split.");
                return;
              }

              // find scene node in selection
              const sceneNode = findNode(editor, {
                at,
                match: { type: ELEMENT_SCENE },
              });

              const isSplittingAScene = sceneNode && ((sceneNode[0] as NodeType).type === ELEMENT_SCENE);

              let pushToNextChapter;

              // Perform split node operations without normalization to avoid conflicts
              withoutNormalizing(editor, () => {
                if (isSplittingAScene) {
                  const sceneNodes = findAllNodesInDocByType<MySceneElement>(
                    editor as BaseEditor,
                    ELEMENT_SCENE
                  );
                  SceneUtils.unwrapAllScenes(editor as PlateEditor, sceneNodes);
                }
                
                // Split nodes,
                // If the cursor is between a text for example, the content after the cursor is pushed into a different node.
                try {
                  splitNodes(editor);
                } catch (err) {
                  console.error("Error during splitNodes:", err);
                }

                const midPoint = getMidPointOfSplit(editor);
                const endPoint = getEndPoint(editor, []);


                if (midPoint.anchor && !Editor.hasPath(editor as BaseEditor, midPoint.anchor.path)) {
                  console.error("Midpoint anchor path is invalid:", midPoint.anchor?.path);
                  return;
                }

                if (!Editor.hasPath(editor as BaseEditor, endPoint.path)) {
                  console.error("End point path is invalid:", endPoint.path);
                  return;
                }

                // Select the stuff that needs to be moved to next chapter
                setSelection(editor, {
                  anchor: midPoint.anchor,
                  focus: endPoint,
                });

                //Get Selected Elements
                pushToNextChapter = editor.getFragment();

                if (pushToNextChapter && pushToNextChapter.length > 0) {
                  const selection = editor.selection;

                  if(!selection){
                    console.error("Selection couldn't be identified !", pushToNextChapter);
                    return;
                  }

                  // Maintain the proper scene structure
                  // Prepend initial body if first node is an ornamental break
                  if (isSplittingAScene && pushToNextChapter[0].type === ELEMENT_ORNAMENTAL_BREAK) {
                    pushToNextChapter.unshift(...initBody);
                  }

                  // Append initial body if last node is an ornamental break
                  if (isSplittingAScene && pushToNextChapter[pushToNextChapter.length - 1].type === ELEMENT_ORNAMENTAL_BREAK) {
                    pushToNextChapter.push(...initBody);
                  }

                  const previousNode = Editor.previous(editor as BaseEditor, {
                    at: selection,
                  });

                  const isAboveNodeOB = previousNode && (previousNode[0] as (BaseElement | BaseEditor) & NodeType).type === ELEMENT_ORNAMENTAL_BREAK;

                  // If the previous node is an OB node, add an empty paragraph after splitting.
                  if (isSplittingAScene && previousNode && isAboveNodeOB) {
                    insertNodes(
                      editor, [
                      {
                        type: ELEMENT_PARAGRAPH,
                        children: [{ text: "" }],
                      }],
                      {
                        at: selection,
                      }
                    );
                  }

                  //Remove selected nodes from current chapter as selected nodes will be moved to next chapter
                  try {
                    removeNodes(editor);
                  } catch (err) {
                    console.error("Error during removeNodes:", err);
                  }
                }
              });

              // TODO:BODY
              // saveChapterBodyUpdates(chapter._id, editor.children);

              await addNewChapter({
                section: getChapterMatterById(chapterMeta._id),
                type: chapterMeta.type,
                chapter: {
                  title: chapterMeta.title + " (SPLIT)",
                  parentChapterId: chapterMeta.parentChapterId,
                },
                body: pushToNextChapter,
              });

              await refreshPDFCache();
            }}
          />
        </div>
      </TooltipIcon>
    </div>
  );
};
