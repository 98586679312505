import { createPluginFactory } from "@udecode/plate";
import { withCustomClipboard } from "./normalizers/withCustomClipboard";

// Custom format identifier for Atticus clipboard data
export const ATTICUS_CLIPBOARD_CUSTOM_FORMAT = "application/x-atticus-slate-fragment";

/**
 * Plugin that adds a custom clipboard data format for content copied from Atticus editors.
 * When content is copied, it adds a special identifier that can be detected during paste operations.
 * This allows for specialized handling of content that originated from Atticus.
 */
export const createCustomClipboardPlugin = createPluginFactory({
  key: ATTICUS_CLIPBOARD_CUSTOM_FORMAT,
  withOverrides: withCustomClipboard,
});
