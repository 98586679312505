import { ELEMENT_PARAGRAPH, PlateEditor, Value, deserializeHtml } from "@udecode/plate";
import { Editor, Transforms, Range, Node } from "slate";
import { preprocessHtml } from "./preprocessHtml";
import { ATTICUS_CLIPBOARD_CUSTOM_FORMAT } from "../../custom-clipboard";

const normalizeHtml = (htmlString: string) => {
    return htmlString.replace(/\s+/g, " ").trim();
};

export const withCombinedDeserialization = (editor: PlateEditor<Value> & Editor) => {
    const { insertData } = editor;
    editor.insertData = (data: DataTransfer) => {

        // handle content copied from atticus
        const isCopiedFromAtticus = data.types.includes(ATTICUS_CLIPBOARD_CUSTOM_FORMAT);
        if(isCopiedFromAtticus) {
            const dataCopiedFromAtticus = data.getData(ATTICUS_CLIPBOARD_CUSTOM_FORMAT);
            const decoded = decodeURIComponent(window.atob(dataCopiedFromAtticus));
            editor.insertFragment(JSON.parse(decoded));
            return;
        }

        const html = data.getData("text/html");
        if (html) {
            const { body } = new DOMParser().parseFromString(html, "text/html");
            const originalHtml = body.innerHTML;
            const processedHtml = preprocessHtml(body.innerHTML);
            const fragment = deserializeHtml(editor, { element: processedHtml });
            const containsList = /<li/.test(originalHtml);
            //check if the selection is collapsed and inside a word
            const { selection } = editor;
            if (selection && Range.isCollapsed(selection)) {
                const [node] = Editor.node(editor, selection.focus.path);
                if (containsList && Node.string(node).length > 0) {
                    //adding an empty line
                    Transforms.insertNodes(editor, {
                        type: ELEMENT_PARAGRAPH,
                        children: [{ text: "" }]
                    } as any);
                    Transforms.move(editor, { distance: -1, unit: "offset" });
                }
            }
            if (fragment && normalizeHtml(processedHtml) !== normalizeHtml(originalHtml)) {
                editor.insertFragment(fragment);
                return;
            }
        }
        insertData(data);
    };
    return editor;
};