import { PlateEditor } from "@udecode/plate";
import { ATTICUS_CLIPBOARD_CUSTOM_FORMAT } from "../createCustomClipboardPlugin";

export const withCustomClipboard = <T extends PlateEditor>(
  editor: T
): PlateEditor => {
  const { setFragmentData } = editor;

  /**
   * Override the editor's setFragmentData method to include our custom format
   * This runs whenever content is copied/cut from the editor
   */
  editor.setFragmentData = (data: DataTransfer) => {
    // Extract and serialize selected content
    const selectedFragments = editor.getFragment();
    const string = JSON.stringify(selectedFragments);

    // Encode the JSON string to base64 to ensure safe and standard clipboard transfer
    // First URI encode to handle special characters, then base64 encode
    const encoded = window.btoa(encodeURIComponent(string));

    // Add our custom format {ATTICUS_CLIPBOARD_CUSTOM_FORMAT} to the clipboard data
    data.setData(ATTICUS_CLIPBOARD_CUSTOM_FORMAT, encoded);

    // fallback to the default method with the modified data
    setFragmentData(data);
  };

  return editor;
};