import { Quota } from "../types/collaboration";

export const getQuotaKey = (type: string): keyof Quota | null => {
  const typeToKeyMap: Record<string, keyof Quota> = {
    co_author: "co_authors",
    editor: "editors",
    beta_reader: "beta_readers",
  };
  return typeToKeyMap[type] || null;
};
