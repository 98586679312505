import {
  Range,
  Transforms,
  Node,
  Editor,
  Text,
  Element,
  Path,
  BaseElement,
  Point,
} from "slate";

import { editorHasMode } from "./utils/isEditorHasMode";
import {
  TrackChangesEditor,
  EditorMode,
  TRACK_CHANGES_OPERATION,
  TrackChangesInfo,
  TrackChangeNodeEntry,
} from "./types";
import { TextDirection } from "slate/dist/interfaces/types";
import { findNode, PlateEditor, setNodes, Value } from "@udecode/plate";
import { v4 as uuidv4 } from "uuid";
import useRootStore from "../../../../store/useRootStore";
import _ from "lodash";
import { ProfileProps } from "../../../../types/auth";
import pluginProperties from "./utils/activePlugins";
import { NodeType } from "../types";
import { can } from "../../../casl/helpers";
import { ELEMENT_SCENE } from "../ornamental-break";


export function toggleEditorMode(
  editor: TrackChangesEditor,
  mode: EditorMode
): void {
  const current = editor.modes[mode];
  editor.modes[mode] = !current;
  editor.onChange();
}

function _getTrackChangesInfo(editor: TrackChangesEditor): TrackChangesInfo {
  const marks = Editor.marks(editor);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  return marks.trackChanges || null;
}



export function withTrackChanges<
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(e) {
  const {
    deleteBackward,
    insertText,
    deleteFragment,
    insertFragment,
    deleteForward,
    apply,
  } = e;
  const editor = e as E & TrackChangesEditor;

  if (!editor.modes) {
    editor.modes = { TrackChanges: false };
  }

  const { user } = useRootStore().authStore;
  const { setEditorCurrentOperation } = useRootStore().bookStore;
  const { getEditorSetting } = useRootStore().appStore;

  let currentTrackChangeId: string | null = null;
  let dateTime: number | null = null;
  let selection: Path;
  let isInitialDeletion: boolean;
  let isFragmented: boolean;
  let isPointChange: boolean;
  let isForwardDeletion: boolean;
  let counter = 3;
  let isTrackChange = false;
  let previousId: string | null = null;
  let isFragmentDeletion = false;

  const resetTrackChangeId = () => {
    currentTrackChangeId = null;
    dateTime = null;
  };

  const tcDeleteFragment = (editor: TrackChangesEditor, selection: Range, currentTrackChangeId: string, user: ProfileProps | null) => {
    isFragmentDeletion = true;
    const selectionRef = Editor.rangeRef(editor, selection);
    Transforms.removeNodes(editor, {
      match: (e) =>
        Text.isText(e) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.trackChanges?.operation === TRACK_CHANGES_OPERATION.TEXT_ADDED,
    });
    const newSelection = selectionRef.unref();
    if (newSelection !== null) {
      Transforms.select(editor, newSelection);
    }
  
    editor.addMark("trackChanges", {
      operation: TRACK_CHANGES_OPERATION.TEXT_DELETED,
      fragment: true,
      tcId: currentTrackChangeId,
      userId: user?._id,
      createdAt: dateTime,
    });
    editor.addMark("replies", []);
  };

  

  editor.onChange = () => {
    /*
        * This is a mechanism to include a unique ID for each track-change
        * It works by comparing the current editor selection with the selection of the last track change
        * If the current selection doesnt mach with the selection of the last track change the ID will be set to null
        * A new value for the ID will be given when the first character of a track change is inserted when the value of the ID is null
    */
    if (editor.selection) {
      const point = Editor.node(editor, editor.selection);
      const trackChanges = (point[0] as TrackChangeNodeEntry).trackChanges;
      const isTrackChanges = trackChanges != null;
      const editorSelection = editor.selection?.anchor.path;
      const isEqual = _.isEqual(selection, editorSelection);
      let deleteForwardCounter = 2;

      if (isTrackChanges) {
        currentTrackChangeId = trackChanges.tcId;
      }

      if (!selection) {
        selection = editor.selection.anchor.path;
      }

      // Handle forward deletion at the beginning of a paragraph 
      if (isForwardDeletion && editorSelection[editorSelection.length - 1] === 1) {
        deleteForwardCounter = 1;
      }

      counter = isPointChange ? counter - 1 : 3;
      if (counter <= 0) {
        isPointChange = false;
      }
      if (isInitialDeletion && counter === 2) {
        isPointChange = false;
      }

      const updatedPath = [
        ...selection.slice(0, -1),
        selection[editorSelection.length - 1] + (isEqual ? 0 : (isInitialDeletion && counter === 2) || counter === 0 ? 1 : isForwardDeletion ? deleteForwardCounter : 0)
      ];

      if (counter === 2) {
        isInitialDeletion = false;
      }

      if (isForwardDeletion) {
        isForwardDeletion = false;
      }

      if (!_.isEqual(updatedPath, editorSelection) && !isTrackChanges) {
        resetTrackChangeId();
      }
    }
  };

  /*
   Insert Text 
 */

  editor.insertText = (text) => {

    let isInsert = true;

    if (editorHasMode(editor, "TrackChanges") && editor.selection) {

      const hasTextInSelection = (editor) => {
        const { selection } = editor;
      
        if (!selection || Range.isCollapsed(selection)) {
          // No selection or selection is collapsed (no selected text)
          return false;
        }
      
        const selectedText = Editor.string(editor, selection);
        return selectedText.length > 0;
      };

      if (!currentTrackChangeId) {
        currentTrackChangeId = uuidv4();
        isPointChange = true;
      }

      if (!dateTime) {
        dateTime = Date.now();
      }
      selection = editor.selection?.anchor.path;
      const point = Editor.node(editor, editor.selection);
      const { trackChanges, ...leafText } = point[0] as TrackChangeNodeEntry;
      const isTrackChangesFragmentTrue = trackChanges?.fragment === true || false;
      const { anchor, focus } = editor.selection;

      if (isTrackChangesFragmentTrue || trackChanges?.operation === "update" || hasTextInSelection(editor)) {
        isInsert = false;
      }

      const trackChangeObj = {
        operation: TRACK_CHANGES_OPERATION.TEXT_DELETED,
        fragment: false,
        tcId: currentTrackChangeId,
        userId: user?._id,
        createdAt: !dateTime ? Date.now() : dateTime,
      };

      setNodes(editor as any,  
        {
          trackChanges: {...trackChangeObj}
        }
       , 
       { match: n => Text.isText(n), split: true  });

      // Get the start of the selection by comparing anchor and focus points
      const start = Point.isBefore(anchor, focus) ? anchor : focus;

      Transforms.setSelection(editor, { anchor: start, focus: start });

      editor.addMark("trackChanges", {
        operation: isInsert ? TRACK_CHANGES_OPERATION.TEXT_ADDED : TRACK_CHANGES_OPERATION.TEXT_UPDATED,
        fragment: false,
        tcId: currentTrackChangeId,
        userId: user?._id,
        createdAt: !dateTime ? Date.now() : dateTime,
      });
      editor.addMark("replies", []);
    }
    insertText(text);
  };

  /*
    Insert a text fragment 
  */

  editor.insertFragment = (fragment: Node[]) => {

    const at = editor.selection;
    if (!at) return;

    // Find the target node (scene element) from the current selection
    const targetNode = findNode(editor, {
      at,
      match: { type: ELEMENT_SCENE },
    });

    const isScene = (targetNode && ((targetNode[0] as NodeType).type) === ELEMENT_SCENE) ?? false;
    const currentFragment: Node[] = isScene && (fragment[0] as BaseElement).children ? (fragment[0] as BaseElement).children : fragment;

    // check for plugin permissions when pasting content

    const filteredNodes = currentFragment.filter((node) => {
      if(["indent", "blockquote", "code_block"].includes((node as BaseElement & NodeType).type)) {
        return can("view", "quote-items-toolbar");
      }

      if((node as BaseElement & NodeType).type === "ornamental-break") {
        return can("view", "scene-break-toolbar");
      }

      if((node as BaseElement & NodeType).type === "page-break") {
        return can("view", "page-break-toolbar");
      }

      if((node as BaseElement & NodeType).type === "endnote") {
        return can("view", "endnotes-toolbar");
      }

      if((node as BaseElement & NodeType).type === "profile") {
        return can("view", "social-media-toolbar");
      }

      return true;
    });

    if (!currentTrackChangeId) {
      currentTrackChangeId = uuidv4();
      previousId = currentTrackChangeId;
    }
    if (!dateTime) {
      dateTime = Date.now();
    }

    if (!editorHasMode(editor, "TrackChanges") || !editor.selection) {
      return insertFragment(filteredNodes);
    }

    tcDeleteFragment(editor, editor.selection, currentTrackChangeId, user);

    let isInsert = true;
    const point = Editor.node(editor, editor.selection);
    const { trackChanges, ...leafText } = point[0] as TrackChangeNodeEntry;
    const isTrackChangesFragmentTrue = trackChanges?.fragment === true || false;

    if (isTrackChangesFragmentTrue || trackChanges?.operation === "update" ) {
      isInsert = false;
    }

    Transforms.insertNodes(
      editor,
      filteredNodes.flatMap((node) => {
        if (Text.isText(node)) {
          return [
            {
              ...node,
              trackChanges: {
                operation: isInsert ? TRACK_CHANGES_OPERATION.TEXT_ADDED : TRACK_CHANGES_OPERATION.TEXT_UPDATED,
                tcId: !currentTrackChangeId ? previousId : currentTrackChangeId,
                userId: user?._id,
                createdAt: !dateTime ? Date.now() : dateTime,
              },
              replies: [],
            },
          ];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
        } else if (Element.isElement(node) && node.type === "p") {

          const updatedChildren: Node[] = node.children.map((child) => {
            if (Text.isText(child)) {
              return {
                ...child,
                trackChanges: {
                  operation: isInsert ? TRACK_CHANGES_OPERATION.TEXT_ADDED : TRACK_CHANGES_OPERATION.TEXT_UPDATED,
                  tcId: !currentTrackChangeId ? previousId : currentTrackChangeId,
                  userId: user?._id,
                  createdAt: !dateTime ? Date.now() : dateTime,
                },
                replies: []
              };
            } else {
              return child;
            }
          });
          return updatedChildren;
        } else {

          return [node];
        }
      }),
      { at: Range.start(editor.selection) }
    );
  };

  /*
   Backward deletions  
 */

  editor.deleteBackward = (unit) => {

    isTrackChange = true;

    if (!editorHasMode(editor, "TrackChanges") || !editor.selection) {
      return deleteBackward(unit);
    } else {
      if (Range.isCollapsed(editor.selection)) {

        if (!currentTrackChangeId) {
          currentTrackChangeId = uuidv4();
          isPointChange = true;
          isInitialDeletion = true;
        }
        if (!dateTime) {
          dateTime = Date.now();
        }
        selection = editor.selection.focus.path;
        const back1 = Editor.before(editor, editor.selection.anchor, {
          distance: 1,
          unit: "character",
        });

        if (!back1) {
          // when we're at the beginning of the document
          return;
        }

        const back1Ref = Editor.pointRef(editor, back1);
        Transforms.select(e, {
          anchor: editor.selection.anchor,
          focus: back1,
        });

        const currentTrackChangesInfo = _getTrackChangesInfo(editor);

        if (
          currentTrackChangesInfo &&
          (currentTrackChangesInfo.operation === TRACK_CHANGES_OPERATION.TEXT_ADDED ||
            currentTrackChangesInfo.operation === TRACK_CHANGES_OPERATION.TEXT_UPDATED)
        ) {
          // actually delete it
          Transforms.delete(editor, {
            at: editor.selection.anchor,
            unit: "character",
            reverse: true,
          });
        } else {
          editor.addMark("trackChanges", {
            operation: TRACK_CHANGES_OPERATION.TEXT_DELETED,
            tcId: currentTrackChangeId,
            userId: user?._id,
            createdAt: dateTime,
          });
          editor.addMark("replies", []);
          const newSelection = back1Ref.unref();
          if (newSelection) {
            Transforms.select(e, newSelection);
          }
        }
      } else {
        if (!currentTrackChangeId) {
          currentTrackChangeId = uuidv4();
        }
        selection = editor.selection.anchor.path;
        tcDeleteFragment(editor, editor.selection, currentTrackChangeId, user);
      }
      isTrackChange = false;
    }
  };

  /*
    Fragment deletions
  */

  editor.deleteFragment = (direction?: TextDirection) => {
    isTrackChange = true;
    let isTrackChanges = false;

    if (!currentTrackChangeId) {
      currentTrackChangeId = uuidv4();
    }
    if (!dateTime) {
      dateTime = Date.now();
    }
    
    if (editor.selection) {
      const point = Editor.node(editor, editor.selection);
      const trackChanges = (point[0] as TrackChangeNodeEntry).trackChanges;
      isTrackChanges = trackChanges != null;

      // prevent deletion if the selection has delete operations
      if (isTrackChanges && trackChanges.operation==="delete") {
        return;
      }
    }

    if (!editorHasMode(editor, "TrackChanges") || !editor.selection || isTrackChanges) {
      return deleteFragment(direction);
    }
    tcDeleteFragment(editor, editor.selection, currentTrackChangeId, user);
    isTrackChange = false;
  };

  /*
    Forward deletions
  */
  editor.deleteForward = (unit) => {
    isTrackChange = true;
    if (editorHasMode(editor, "TrackChanges") &&  editor.selection) {
      if (Range.isCollapsed(editor.selection)) {

        if (!currentTrackChangeId) {
          currentTrackChangeId = uuidv4();
          isPointChange = true;
          isForwardDeletion = true;
        }
        if (!dateTime) {
          dateTime = Date.now();
        }
        selection = editor.selection.focus.path;
        const back1 = Editor.after(editor, editor.selection.anchor, {
          distance: 1,
          unit: "character",
        });

        if (!back1) {

          return;
        }

        const back1Ref = Editor.pointRef(editor, back1);
        Transforms.select(e, {
          anchor: editor.selection.anchor,
          focus: back1,
        });

        const currentTrackChangesInfo = _getTrackChangesInfo(editor);

        if (
          currentTrackChangesInfo &&
          (currentTrackChangesInfo.operation === TRACK_CHANGES_OPERATION.TEXT_ADDED ||
            currentTrackChangesInfo.operation === TRACK_CHANGES_OPERATION.TEXT_UPDATED)
        ) {
          // actually delete it
          Transforms.delete(editor, {
            at: editor.selection.anchor,
            unit: "character",
            reverse: true,
          });
        } else {
          editor.addMark("trackChanges", {
            operation: TRACK_CHANGES_OPERATION.TEXT_DELETED,
            tcId: currentTrackChangeId,
            userId: user?._id,
            createdAt: dateTime,
          });
          editor.addMark("replies", []);
          const newSelection = back1Ref.unref();
          if (newSelection) {
            Transforms.select(e, newSelection);
          }
        }
      }
    } else {
      deleteForward(unit);
    }
    isTrackChange = false;
  };

   /*
    Apply Formatting
  */

  function isPluginPropertyPresent(newProperties) {
    // Check if any property in newProperties has a null value
    if (Object.values(newProperties).some(value => value === null)) {
      return false;
    }

    // Check if any property is in pluginProperties
    return Object.keys(newProperties).some(property => pluginProperties.includes(property));
  }
  function isTrackChangesPresent(newProperties) {
    return Object.keys(newProperties).some(property => property === "trackChanges");
  }

  editor.apply = (operation) => {
    
    /**
     * Checks if the editor is in "search" view mode and the "show" setting is enabled.
     * If both conditions are met, sets the current operation in the editor to track 
     * find-and-replace functionality.
     */
    const editor_setting = getEditorSetting();
    if(editor_setting.show && editor_setting.view === "search"){
      setEditorCurrentOperation(operation);
    }  

    /*
      1) The pluginProperties array contains key-value pairs for all supported plugins with track-changes.
      2) For each operation, the affected node is identified using its path value.
      3) The current formatting properties of the node are captured and saved as metadata within the trackChanges object.
      4) This metadata is used to restore the node to its original state if the changes are declined.
  */

    if (editorHasMode(editor, "TrackChanges") && editor.selection) {
      const operations = {};
      if (operation.type === "set_node") {
        const node = Node.get(editor, operation.path);
        let isDefaultTcOperation = false;

        if ((node as TrackChangeNodeEntry).trackChanges) {
          const { operation: tcOperation } = (node as TrackChangeNodeEntry).trackChanges;
          isDefaultTcOperation = (tcOperation === "insert" || tcOperation === "update" || tcOperation === "delete");
        }

        // Iterate over the node's properties to track down the existing formatting properties
        if (!(node as TrackChangeNodeEntry).trackChanges) {
          for (const key in node) {
            if (pluginProperties.includes(key)) {
              // If the key is a known mark from the pluginProperties, add it to the operations object
              operations[key] = node[key];
            }
          }
        }
        const shouldTrackChanges = () => {
          const hasPluginProperty = isPluginPropertyPresent(operation.newProperties) ||
            (isPluginPropertyPresent(operation.properties) &&
              !isTrackChangesPresent(operation.properties));

          return hasPluginProperty;
        };

        if (shouldTrackChanges() && !isTrackChange && !isDefaultTcOperation) {
          if (!currentTrackChangeId) {
            currentTrackChangeId = uuidv4();
          }
          if (!dateTime) {
            dateTime = Date.now();
          }
          selection = editor.selection?.anchor.path;

          operation.newProperties = {
            ...operation.newProperties,
            trackChanges: {
              operation: TRACK_CHANGES_OPERATION.TEXT_FORMATTED,
              tcId: currentTrackChangeId,
              userId: user?._id,
              createdAt: dateTime,
              ...((node as TrackChangeNodeEntry).trackChanges?.formatting
                ? { formatting: (node as TrackChangeNodeEntry).trackChanges?.formatting }
                : {
                  formatting: {
                    ...operations,
                  },
                }),
            },
          };
        }
      }
    }
    apply(operation);
  };

  return editor;
}