import { AtticusClient } from "../../api/atticus.api";
import { db } from "../../db/bookDb";
import { BookStore } from "../../store/Book";

/**
 * instance of BookStore class
 */
class BookSnapshotSync {
    constructor(private bookStore: BookStore) { }

    /**
     * update each book in IndexedDB to manage proper DB sync by adding hourly synced time
     */
    updateBookSyncTime = async (bookId: string, syncTime: Date): Promise<void> => {
        try {
            await db.books.update(bookId, { lastSyncTime: syncTime.toISOString() });
            console.log(`Updated lastSyncTime for book: ${bookId}`);
        } catch (error) {
            console.error(`Failed to update lastSyncTime for book: ${bookId}`, error);
        }
    }

    /**
     * fetch all books from IndexedDB, capture snapshots, and saves in DB
     */
    saveAllBookSnapshots = async (): Promise<void> => {
        try {
            const booksInDB = await db.books.toArray();
            if (!booksInDB.length) {
                console.log("No books found in IndexedDB");
                return;
            }

            const snapshotPromises = booksInDB.map(async (book: IBookStore.Book) => {
                try {
                    const { _id, lastUpdateAt, lastSyncTime } = book;

                    if (lastSyncTime && lastUpdateAt && lastUpdateAt <= lastSyncTime) {
                        console.log(`Skipping sync for book: ${_id}, no changes since last sync`);
                        return;
                    }
                    const snapshot = await this.bookStore.getBookSnapshot(book._id);
                    if (snapshot) {
                        await AtticusClient.SaveSnapshot(book._id, JSON.stringify(snapshot), "collaboration");
                        console.log(`Snapshot for book ${book._id} was successful`);
                        await this.updateBookSyncTime(_id, new Date());
                    }
                } catch (error) {
                    console.error(`Error saving snapshot for book ${book._id}:`, error);
                }
            });
            await Promise.all(snapshotPromises);
            console.log("All snapshots saved successfully");
        } catch (error) {
            console.error("Error during snapshot saving process: ", error);
        }
    };

    /**
     * save snapshots in hourly intervals
     */
    startHourlySnapshotJob = async (): Promise<void> => {
        console.log("Starting hourly snapshot sync!");
        await this.saveAllBookSnapshots();
    };
}

export const snapshotSync = new BookSnapshotSync(new BookStore());