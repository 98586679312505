import { BaseEditor, NodeEntry, Path, Editor, Transforms, Node, Text, BaseElement, Element, Range } from "slate";
import {
  isElement,
  getParentNode,
  TRange,
  liftNodes,
  findNode,
  insertNodes,
  removeNodes,
  withoutNormalizing,
} from "@udecode/plate-core";

import {
  MyOrnamentalBreakElement,
  MySceneElement,
} from "../../../config/typescript";
import { ELEMENT_SCENE } from "../createScenePlugin";
import { ELEMENT_ORNAMENTAL_BREAK } from "../createOrnamentalBreakPlugin";
import { editorHasMode } from "../../track-changes/utils";

import {
  findAllNodesInDocByType,
  getAdjoiningSiblings,
} from "../../../../../utils/slate";
import { SceneUtils } from "../../../../../utils/scene/sceneServices";
import { ELEMENT_PARAGRAPH, setNodes } from "@udecode/plate";
import { ELEMENT_ALIGN_CENTER, ELEMENT_ALIGN_RIGHT } from "../../alignment";
import { Scene } from "../../../../../types/scene";
import { TRACK_CHANGES_OPERATION, TrackChangesEditor } from "../../track-changes/types";
import useRootStore from "../../../../../store/useRootStore";
import { v4 as uuidv4 } from "uuid";
import { NodeType } from "../../types";

export function withScenes(
  editor,
  getCurrentScene: () => {
    chapterId: string;
    scene: Scene;
  } | null,
  handleScenes: () => void
) {
  const { user } = useRootStore().authStore;
  const plateEditor = editor as BaseEditor & TrackChangesEditor;
  const { deleteFragment, normalizeNode, deleteBackward, deleteForward, insertFragment } =
    plateEditor;
  const { isTrackChanges } = useRootStore().trackChangesStore;

    // handle content pasted inside scenes
    if (!isTrackChanges) {

      plateEditor.insertFragment = (fragments: Node[]) => {
      const at = editor.selection;
      if (!at) return;

      // Find the target node (scene element) from the current selection
      const targetNode = findNode(editor, {
        at,
        match: { type: ELEMENT_SCENE },
      });

      // Verify that the target node found and it is indeed a scene element
      if (targetNode && targetNode[0].type === ELEMENT_SCENE) {
        const nodesToInsert: Node[] = [];

        // Process each fragment passed to the method
        fragments.forEach((fragment: Node) => {
          // If the fragment is itself a scene element, spread its children to avoid nested scenes
          if ((fragment as NodeType).type === ELEMENT_SCENE) {
            nodesToInsert.push(...(fragment as BaseElement).children);
          } else {
            // Otherwise, add the fragment directly
            nodesToInsert.push(fragment);
          }
        });

        // Using withoutNormalizing to prevent normalization during the operation
        withoutNormalizing(editor, () => {
          Transforms.insertNodes(
            editor,
            nodesToInsert.flatMap((node) => {
              if (Text.isText(node)) {
                return [
                  {
                    ...node,
                  },
                ];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
              } else if (Element.isElement(node) && node.type === "p") {

                const updatedChildren: Node[] = node.children.map((child) => {
                  if (Text.isText(child)) {
                    return {
                      ...child,
                    };
                  } else {
                    return child;
                  }
                });
                return updatedChildren;
              } else {

                return [node];
              }
            }),
            { at: at.focus }
          );

        });
        return;
      }

      // If the target node is not a scene, fallback to the default
      return insertFragment(fragments) ;
    };
    }

  /**
   * Overwrite editor delete operations when a user is inside of a scene to prevent
   * accidential deletions of content in other scenes
   * */
  plateEditor.deleteBackward = (unit) => {
    const currentScene = getCurrentScene();
    if (!currentScene) {
      deleteBackward(unit);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor) return;
    const { anchor } = selection;
    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    if (isAnchorInScene) {
      deleteBackward(unit);
      return;
    }
  };

  plateEditor.deleteForward = (unit) => {
    const currentScene = getCurrentScene();
    if (!currentScene) {
      deleteForward(unit);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor) return;
    const { anchor } = selection;
    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    if (isAnchorInScene) {
      deleteForward(unit);
      return;
    }
  };

  
  plateEditor.deleteFragment = (direction) => {

    const currentScene = getCurrentScene();
    if (!currentScene) {
      deleteFragment(direction);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor || !selection.focus) return;
    const { anchor, focus } = selection;

    const trackChangeObj = {
      operation: TRACK_CHANGES_OPERATION.TEXT_DELETED,
      fragment: true,
      tcId: uuidv4(),
      userId: user?._id,
      createdAt: Date.now(),
    };

    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const focusAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: focus,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    const isFocusInScene = !!focusAncestorScene;
    /** if both anchor and focus of the current selection is outside of the active scene, do nothing */
    if (!isAnchorInScene && !isFocusInScene) {
      return;
    }
    /** if both anchor and focus of the current selection is within the active scene, delete the selection without modifying  */
    if (isAnchorInScene && isFocusInScene) {

      if (editorHasMode(plateEditor, "TrackChanges")) {

        setNodes(plateEditor as any,
          {
            trackChanges: { ...trackChangeObj }
          }
          ,
          { match: n => Text.isText(n), split: true });
      } else {
        Transforms.delete(plateEditor, { at: selection });
        return;
      }
    }
    if (isAnchorInScene || isFocusInScene) {
      /** check if anchor point is before focus (can select fragment top to bottom or bottom to top) */
      const isAnchorBeforeFocus = Path.isBefore(anchor.path, focus.path);
      /** if only anchor is within the current scene */
      if (isAnchorInScene) {
        const sceneStartPoint = Editor.start(
          plateEditor,
          anchorAncestorScene[1]
        );
        const sceneEndPoint = Editor.end(plateEditor, anchorAncestorScene[1]);
        if (isAnchorBeforeFocus) {

          if (editorHasMode(plateEditor, "TrackChanges")) {

            setNodes(plateEditor as any,
              {
                trackChanges: { ...trackChangeObj }
              }
              ,
              {
                at: { anchor, focus: sceneEndPoint },
              });
          } else {
            Transforms.delete(plateEditor, {
              at: { anchor, focus: sceneEndPoint },
            });
          }
          return;

        } else {
          if (!editorHasMode(plateEditor, "TrackChanges")) {
            Transforms.delete(plateEditor, {
              at: { anchor: sceneStartPoint, focus: anchor },
            });
          }
          return;
        }
      }
      /** if only focus is within the current scene */
      if (isFocusInScene) {
        const sceneStartPoint = Editor.start(
          plateEditor,
          focusAncestorScene[1]
        );
        const sceneEndPoint = Editor.end(plateEditor, focusAncestorScene[1]);
        if (isAnchorBeforeFocus) {

          if (editorHasMode(plateEditor, "TrackChanges")) {

            setNodes(plateEditor as any,
              {
                trackChanges: { ...trackChangeObj }
              }
              ,
              {
                at: { anchor: sceneStartPoint, focus },
              });

          } else {

            Transforms.delete(plateEditor, {
              at: { anchor: sceneStartPoint, focus },
            });
          }
          return;
        } else {
          if (editorHasMode(plateEditor, "TrackChanges")) {

            setNodes(plateEditor as any,
              {
                trackChanges: { ...trackChangeObj }
              }
              ,
              {
                at: { anchor: focus, focus: sceneEndPoint },
              });
          } else {
            Transforms.delete(plateEditor, {
              at: { anchor: focus, focus: sceneEndPoint },
            });
          }
          return;
        }
      }
    }
  };

  

  /**
   * Normalizer logic
   */
  plateEditor.normalizeNode = (entry: NodeEntry) => {
    const [node, path] = entry;
    
    // Retrieve all ornamental break nodes in doc
    const ornamentalBreakNodes =
      findAllNodesInDocByType<MyOrnamentalBreakElement>(
        editor,
        ELEMENT_ORNAMENTAL_BREAK
      );

      /**
       * Unwraps all scene nodes if invalid states are detected after the initial normalization process.
       * This allows the rest of the normalizer to rewrap the nodes into a valid structure.
       *
       * Steps:
       * 1. Get all scene nodes in the chapter using findAllNodesInDocByType.
       * 2. Unwrap all scene nodes if ornamental breaks are absent in the chapter.
       * 3. Evaluate each scene node against the following conditions:
       *    - The initial normalization process is completed.
       *    - Scene node is at the root level.
       *    - Two or more scene nodes are adjacent.
       * 4. If the conditions are met, unwrap all scene nodes.
       * 5. Rest of the normalizer will handle rewrapping nodes into a valid structure.
       */
      withoutNormalizing(editor, () => {
        const sceneNodesToSanitize = findAllNodesInDocByType<MySceneElement>(
          editor as BaseEditor,
          ELEMENT_SCENE
        );

        if (sceneNodesToSanitize.length === 0) {
          return;
        }

        if (ornamentalBreakNodes.length === 0) {
          SceneUtils.unwrapAllScenes(editor, sceneNodesToSanitize);
          return;
        }
  
        let shouldUnwrapAll = false;
        sceneNodesLoop: for (const [_,sceneNode] of sceneNodesToSanitize.entries()) {
          const [node, path] = sceneNode;

          // Check for nested scenes or ornamental breaks within the scene
          for (const [child, childPath] of Node.children(editor, path)) {
            if (
              isElement(child) &&
              [ELEMENT_SCENE, ELEMENT_ORNAMENTAL_BREAK].includes(child.type)
            ) {
              shouldUnwrapAll = false;
              break sceneNodesLoop; // Exit: Initial normalization process is incomplete
            }
          }
  
          // Check if the scene node is not at the root level
          if (path.length !== 1) {
            shouldUnwrapAll = false;
            break;
          }
  
          // Get the adjoining nodes of the current scene node
          const { previous, next } = getAdjoiningSiblings(editor, path);
  
          // Check if the previous or next sibling is also a scene node
          if (
            SceneUtils.isSceneElement(previous?.[0]) || SceneUtils.isSceneElement(next?.[0])
          ) {
            shouldUnwrapAll = true;
          }
        }
  
        // If any condition is met and shouldUnwrapAll = true, unwrap all scene nodes
        if (shouldUnwrapAll) {
          SceneUtils.unwrapAllScenes(editor, sceneNodesToSanitize);
        }
  
        // Rest of the normalizer will handle rewrapping the nodes into a valid structure.
      });

    // Iterate through each formatted ornamental break to ensure proper scene wrapping.
    ornamentalBreakNodes.forEach((obNodeEntry) => {
      const isParentAScene =
        getParentNode(editor, obNodeEntry[1])?.[0].type === ELEMENT_SCENE;
      const isNestedBlockElement = 
        [ELEMENT_ALIGN_RIGHT, ELEMENT_ALIGN_CENTER].includes((getParentNode(editor, obNodeEntry[1])?.[0].type) as string);
      const adjoiningSiblings = getAdjoiningSiblings(editor, obNodeEntry[1]);

      // Skip processing if both previous and next siblings are already scene nodes.
      if (
        isElement(adjoiningSiblings.previous?.[0]) &&
        adjoiningSiblings.previous?.[0].type === ELEMENT_SCENE &&
        isElement(adjoiningSiblings.next?.[0]) &&
        adjoiningSiblings.next?.[0].type === ELEMENT_SCENE
      )
        return;

      if (!isParentAScene && !isNestedBlockElement) {
        try {
          const isAtDocumentStart =
            Editor.start(editor, []).path.toString() ===
            Editor.point(editor, obNodeEntry[1], {
              edge: "start",
            }).path.toString();
          if (!isAtDocumentStart) {
            // Setting the topRange from the start of the document until the top of the ornamental-break
            const topRange: TRange = {
              anchor: Editor.start(editor, []),
              focus: Editor.point(editor, obNodeEntry[1], {
                edge: "start",
              }),
            };

            // Setting the bottomRange from the end of the ornamental-break to the end of the document
            const bottomRange: TRange = {
              anchor: Editor.point(editor, obNodeEntry[1], { edge: "end" }),
              focus: Editor.end(editor, []),
            };

            const blankNode = {
              type: "p", 
              children: [{ text: "" }],
            };
            
            // Check if the bottom range is collapsed to determine if there is a node following a scene break.
            // If the bottom range is collapsed, insert a "p" node right after the scene break.

            if (Range.isCollapsed(bottomRange)) {

              if (Editor.hasPath(editor, bottomRange.anchor.path)) {

                Transforms.insertNodes(editor, blankNode, { at: bottomRange });

              } else {

                Transforms.insertNodes(editor, blankNode, { at: Editor.end(editor, []) });
              }
            }

            // Wrap nodes at the topRange and bottomRange with a scene element
            // The combined behavior of the liftNode and {split:true} of wrapNodes will break the scenes into separate nodes
            Transforms.wrapNodes(
              editor,
              { type: ELEMENT_SCENE, children: [] } as any,
              { at: bottomRange, split: true, mode: "all" } as any
            );
            Transforms.wrapNodes(
              editor,
              { type: ELEMENT_SCENE, children: [] } as any,
              { at: topRange, split: true, mode: "all" } as any
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    });

    // If the current node is a scene, lift its children if they are scenes or ornamental breaks.
    if (isElement(node) && node.type === ELEMENT_SCENE) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (
          isElement(child) &&
          [ELEMENT_SCENE, ELEMENT_ORNAMENTAL_BREAK].includes(child.type)
        ) {
          liftNodes(editor, {
            at: childPath,
          });
          return;
        }
      }
    }

    // Traverse and lift child nodes for pre-existing scene nodes to ensure correct hierarchy.
    const preSceneNodes = findAllNodesInDocByType<MySceneElement>(
      editor,
      ELEMENT_SCENE
    );
    for (const [index, sceneNode] of preSceneNodes.entries()) {
      for (const [child, childPath] of Node.children(editor, sceneNode[1])) {
        if (
          isElement(child) &&
          [ELEMENT_SCENE, ELEMENT_ORNAMENTAL_BREAK].includes(child.type)
        ) {
          liftNodes(editor, {
            at: childPath,
          });
          return;
        }
      }
    }

    // Update the sceneIndex(s)
    const sceneNodes = findAllNodesInDocByType<MySceneElement>(
      editor,
      ELEMENT_SCENE
    );
    for (const [index, sceneNode] of sceneNodes.entries()) {
      Transforms.setNodes(
        editor,
        {
          ...sceneNode[0],
          sceneIndex: index,
        } as any,
        { at: sceneNode[1] }
      );
    }

    // Update scene information in the application state upon ornamental break change
    const isOrnamentalBreakNode =
      isElement(node) && node.type === ELEMENT_ORNAMENTAL_BREAK;
    if (isOrnamentalBreakNode) {
      handleScenes();
      SceneUtils.updateSceneTitles(editor);
    }
    normalizeNode(entry);
  };
  return editor;
}
