import { default as smartquotes } from "@surge-global-engineering/smartquotes";

import { isEmpty } from "./strings";

interface KeyValMap {
  [key: string]: string | boolean
}

interface GetEditorTypeReturn {
  type: string | null;
  urlFragments: string[];
}

export type AllPropertiesRequired<T> = { [P in keyof T]: Required<NonNullable<T[P]>> };

export const getHashMap: (hashString: string) => KeyValMap = (hashString) => {
  const keyValMap: KeyValMap = {};

  const arr = hashString.replace("#", "").split(",");
  for (const keyValStr of arr) {
    const tmp = keyValStr.split("=");
    if (tmp.length === 2) {
      keyValMap[tmp[0]] = tmp[1] as string;
      continue;
    }
    if (tmp.length === 1) {
      keyValMap[tmp[0]] = true;
    }
  }
  return keyValMap;
};

export const getEditorType = (pathname: string): GetEditorTypeReturn => {
  const urlFragments = pathname
    .split("/")
    .filter((fragment) => !isEmpty(fragment));

  let type: string | null = null;

  if (urlFragments.length > 0 && urlFragments[0]) {
    type = urlFragments[0];
  }

  return {
    type,
    urlFragments,
  };
};

export const removeKey = (arr: any, k: string) => Object.keys(arr).reduce((newObj, key) => {
  if (key !== k) {
    newObj[key] = arr[key];
  }
  return newObj;
}, {});

export const removeSmartQuotes = (text) => {
  return text
    .replace(/[“”″]/g, "\"")
    .replace(/[‘’′]/g, "'")
    .replace(/'{2}/g, "'");
};

export const objectKeysEqual = (
  sourceObject: Record<string, unknown>,
  destinationObject: Record<string, unknown>
): boolean => {
  return (
    JSON.stringify(Object.keys(sourceObject)) ===
    JSON.stringify(Object.keys(destinationObject))
  );
};

export const isOnlyQuote = (text: string): boolean => {
  if (isEmpty(text)) {
    return false;
  }

  return text?.match(/^(“|”|″|"|‘|’|′|')$/i) !== null;
};

export const replaceQuotesWithCurlyOpening = (text: string): string => {
  if (isEmpty(text)) {
    return text;
  }

  return text.replace(/^(“|”|″|")$/g, "“").replace(/^(|‘|’|′|')$/g, "‘");
};

export const applySmartQuotes = (content) => {
  if (content && content.length > 0) {
    const firstElement = content[0];

    const trimmedText = firstElement?.text?.trim() || "";

    // De-fragment records.
    if (isOnlyQuote(trimmedText)) {
      let editingIndex = 0;

      for (const [i, child] of content.entries()) {
        if (i === 0) continue;
        if (child === null || child === undefined) continue;

        const latestChild = content[editingIndex];

        if (objectKeysEqual(latestChild, child)) {
          content[editingIndex].text = removeSmartQuotes(
            `${content[editingIndex]?.text}${child?.text}`
          );
          content[i].text = "";
        } else {
          editingIndex = i;
        }
      }
    }

    // Apply smart quotes.
    for (let i = 0; i < content.length; i++) {
      const { text = null, children = [] } = content[i];

      if (children.length > 0) {
        content[i].children = applySmartQuotes(children);
      } else {
        if (!isEmpty(text)) {
          const manualStartingQuote = i == 0 && content.length > 1 && content[i + 1] !== null && content[i + 1] !== undefined && isOnlyQuote(text?.trim()) && !objectKeysEqual(content[i], content[i+1]);

          if (manualStartingQuote) {
            content[i].text = replaceQuotesWithCurlyOpening(content[i].text);
          } else {
            content[i].text = smartquotes(removeSmartQuotes(text));
          }
        }
      }
    }
  }

  return content;
};

export const countChars = content => {
  let count = content.length > 1 ? content.length - 1 : 0;
  content.forEach(value => {
    count += value["children"][0]["text"].length;
  });
  return count;
};

export const checkHttps = content => {
  if (content.toLowerCase().startsWith("https://") || content.toLowerCase().startsWith("http://")) {
    const regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?!&//=]*)/gi;
    const urls = content.match(regexp);
    if(urls) {
      return true;
    } else {
      return false;
    }
  } else if (content.toLowerCase().startsWith("mailto:")) {
    return true;
  } else {
    return false;
  }
};

export const zeroPad = (num, places) => {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
};

export function makeid(length) {
  let result           = "";
  const characters       = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
 return result;
}

export const shouldIncludeChapterInPrint = (chapter: { includeIn?: "all" | "ebook" | "print" | "none" }): boolean => {
  if (!chapter.includeIn) return true;
  return !["ebook", "none"].includes(chapter.includeIn);
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const doSearchReplace = (content: any, search: IBookStore.SearchParams, replaceTerm: string) => content.map(value => {
  const type = value["type"];
  let children = value.children as unknown as Node[];

  const typesAligned = ["align_center", "align_left", "align_right"];
  const typesList = ["ol", "ul", "li"];
  const typeCallout = ["calloutbox"];
  const typeScene = ["scene"];
  const isNested = [...typesAligned, ...typesList, ...typeCallout, ...typeScene].includes(type);

  /*
    Align, Callout and List type of content has nested properties, call the function again to run for nested contents
  */
  if (isNested)
    children = doSearchReplace(children, search, replaceTerm);
  else {
    children = doReplaceInText(children, search, replaceTerm);
  }

  return {
    ...value,
    children
  };
});

export const sanitizeRegexCharacters = (word: string): string => {
  const illegalChars = [".", "(", ")", "[", "]", "|", "{", "}", "*", "+", "?", "^", "$", "/", "-", "\\"];

  return word.split("").map((char => {
    return illegalChars.includes(char) ? `\\${char}` : char;
  })).join("");
};


const doReplaceInText= (arr: any[], s: IBookStore.SearchParams, r: string) => arr.map(ds => {
    if(ds["text"]){
        const {q, caseSensitive, wholeWord} = s;
        const rt = ds["text"];
       
        const regExpPattern = wholeWord ? `(?<!\\w)(${sanitizeRegexCharacters(q)})(?!\\S)` : sanitizeRegexCharacters(q);
        const regExpFlags = caseSensitive ? "g" : "gi";
        const st = new RegExp(regExpPattern, regExpFlags);

        // Escaping $ symbol to make the replace term as literal
        const sanitizedReplaceTerm = r.replace(new RegExp("\\$", "g"), "$$$");

        return {
            ...ds,
            text: rt.replace(st, sanitizedReplaceTerm)
        };
    } else {
        return ds;
    }
});


export const doKeywordCount = (content: any, s: IBookStore.SearchParams) =>  {
    let count = 0;
    if (content && content.length > 0) {
        content.forEach(value => {
            const t = value["type"];
            const d = value["children"] as any[];
            const isAligned = t === "align_center" || t === "align_left" || t === "align_right";
            const isList = t === "ol" || t === "ul";
            const isCallout = t === "calloutbox";
            const isTextMessage = t === "text_messages";
            const isScene = t === "scene";

            if (isAligned || isCallout || isTextMessage || isScene) {
                count += doKeywordCount(d, s);
            }

            if (isList) {
                d.forEach(li => {
                    const ls = li["children"] as Node[];
                    count = count + doKeywordCount(ls, s);
                });
            }

            if (!isAligned && !isList && !isCallout) {
                count += doKeywordCountInText(d, s);
            }
        });
    }
    return count;
};



export const doKeywordCountInText = (arr: any[], term: IBookStore.SearchParams) => {
    let count = 0;
    const {q, wholeWord, caseSensitive} = term;
    arr && arr.forEach(ds => {
        if(ds.text && ds.text.length > 2){
            let t = ds.text;
            let s = q;
            let parts = [];
    
            if(!caseSensitive){
                t = t.toLowerCase();   
                s = s.toLowerCase();
            }
    
            if(wholeWord){
                parts = t.split(new RegExp(`(?<!\\w)(${sanitizeRegexCharacters(s)})(?!\\S)`)).filter((e:string) => e !== s);
            } else {
                parts = t.split(s);
            }
    
            count += parts.length - 1;
            // if (s && s.length != 0 && s.match(/\b[-?(\w+)?]+\b/gi)) {
            //     s = s.replace(/(^\s*)|(\s*$)/gi, "");
            //     s = s.replace(/[ ]{2,}/gi, " ");
            //     s = s.replace(/\n /, "\n");
            //     count += s.split(r).length;
            // }
        }
        
    });

    return count;
};

export const ContentAlteringOpTypes = ["insert_text", "remove_text", "insert_node", "merge_node"];

export const getPropertiesByStyle = (styles: IThemeStore.HeaderFontStyle[]) => {
  let styleObject = {};
  styles.map(style => {
    switch(style){
      case "bold":
        styleObject = {...styleObject, fontWeight: "900"};
        break;
      case "italic":
        styleObject = {...styleObject, fontStyle: "italic"};
        break;
      case "small-caps":
        styleObject = {...styleObject, fontVariant: "small-caps"};
        break;
      case "underlined":
        styleObject = {...styleObject, textDecoration: "underline"};
        break;
    }
  });
  return styleObject;
};

function randomDate(start, end, startHour, endHour) {
  const date = new Date(+start + Math.random() * (end - start));
  const hour = startHour + Math.random() * (endHour - startHour) | 0;
  date.setHours(hour);
  return date;
}
export const validateSort = (sortValue: string) : IShelfStore.BookSortOptionType => {
  const sortOptions = ["recently-added", "date-modified", "alphabetically-asc", "project-asc", "author-asc", "version-asc"];
  if(sortOptions.indexOf(sortValue) > -1) 
    return sortValue as IShelfStore.BookSortOptionType;
  else 
    return "date-modified";
};

export const sortShelfItems = <ShelfItemType extends IShelfStore.ShelfItemExtendedTypes>(
  shelfItems: ShelfItemType[],
  sortOption: IShelfStore.BookSortOptionType
): ShelfItemType[] => {
  const sortOptionToProperty = {
    "recently-added": "createdAt",
    "date-modified": "lastUpdateAt",
    "alphabetically-asc": "title",
    "project-asc": "project",
    "author-asc": "author",
    "version-asc": "versionTags"
  };

  const sortBy = sortOptionToProperty[sortOption];
  if (!sortBy) return shelfItems;

  const isSortByDate = sortBy === "createdAt" || sortBy === "lastUpdateAt";
  const isSortByArray = sortBy === "author" || sortBy === "versionTags";

  return shelfItems.sort((a, b) => {
    if (isSortByDate) {
      // Sort dates in descending order by default
      const dateA = new Date(a[sortBy] || "").getTime();
      const dateB = new Date(b[sortBy] || "").getTime();
      return dateB - dateA;
    } else {
      // Handle strings or arrays (e.g., first element of an array)
      const valueA = isSortByArray ? (a[sortBy]?.[0] || "").toLowerCase() : (a[sortBy] || "").toString().toLowerCase();
      const valueB = isSortByArray ? (b[sortBy]?.[0] || "").toLowerCase() : (b[sortBy] || "").toString().toLowerCase();

      if (!valueA && !valueB) return 0;
      if (!valueA) return 1;
      if (!valueB) return -1;
      
      return valueA.localeCompare(valueB); // Ascending by default for strings
    }
  });
};

export const getArrayDifference = <T>(array1: T[], array2: T[]):T[] => {
  return array1.filter(item1 => !array2.includes(item1));
};

export const getArrayIntersection = <T>(array1: T[], array2: T[]):T[] => {
  return array1.filter(item1 => array2.includes(item1));
};

export const getObjectArrayDifference = <T,V>(array1: T[], array2: V[], key: string): T[] => {
  return array1.filter(item1 => !array2.some(item2 => item1[key] === item2[key]));
};

export const getObjectArrayIntersection = <T,V>(array1: T[], array2: V[], key: string): T[] => {
  return array1.filter(item1 => array2.some(item2 => item1[key] === item2[key]));
};


export async function promiseAllInBatches<T>(
  task: (item: T) => Promise<any>, 
  items: T[], 
  batchSize: number
): Promise<any[]> {
  let position = 0;
  let results: any[] = [];
  
  while (position < items.length) {
    const itemsForBatch = items.slice(position, position + batchSize);
    results = [...results, ...await Promise.all(itemsForBatch.map(item => task(item)))];
    position += batchSize;
  }
  
  return results;
}

export const withTimeout = <T>(promise: Promise<T>, time: number): Promise<T> => {
  return Promise.race([
    promise,
    new Promise<T>((_, reject) =>
      setTimeout(() => reject(new Error("Timeout exceeded")), time)
    ),
  ]);
};
